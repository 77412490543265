import { useParams, useNavigate } from "react-router-dom";
import { ArrowBackButton } from "../../../../components/navigation/BackButton";
import { Spacer } from "../../../../components/ui/Spacer";
import { SimpleInput } from "../../../../components/ui/inputs/SimpleInput";
import { useState } from "react";
import { PrimaryButton } from "../../../../components/ui/buttons/PrimaryButton";
import { gql, useMutation } from "@apollo/client";
import { Error } from "../../../../components/ui/text/Error";
import { Loader } from "../../../../components/ui/Loader";

const LINK_DEVICE = gql`
    mutation($sn: String!, $collectPointId: Float!){
        linkDevice(SN: $sn, collectPointId: $collectPointId) {
            id
            SN
        }
    }
`

export const LinkDeviceToCollectPoint = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [linkDevice, linkDeviceResponse] = useMutation(LINK_DEVICE);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [SN, setSN] = useState<string>("");

    const handleLinkDevice = async () => {
        if (!SN) {
            setErrorMessage("Veuillez entrer un numéro de série");
            return;
        }
        await linkDevice({
            variables: {
                sn: SN,
                collectPointId: Number(id)
            },
            onError: (error) => {
                setErrorMessage(error.message);
            },
            onCompleted: () => {
                navigate(`/points-de-collecte/${id}/douchettes`);
            }
        });
    };

    return (
        <div>
            <div className="flex items-center gap-3 pt-5">
                <ArrowBackButton />
                <span className="font-semibold text-xl">Lier une douchette</span>
            </div >

            <Spacer />
            <SimpleInput label="Numéro de série de l'appareil" value={SN} onChange={setSN} placeholder="Numéro de série" />
            <Spacer />

            {errorMessage && <div><Error>{errorMessage}</Error><Spacer /></div>}
            {linkDeviceResponse.loading && <div><Loader /></div>}
            <PrimaryButton onClick={handleLinkDevice}>Lier la douchette</PrimaryButton>
        </div>
    );
}