export const Toggle = ({ label, value, setValue }: { label: string; value: boolean; setValue: (React.Dispatch<React.SetStateAction<boolean>>) }) => {
    const handleChange = () => {
        setValue(!value);
    };

    return (
        <div className="w-full lg:items-center lg:flex lg:max-w-[70%]">
            <label className="block text-sm text-gray-700 flex-1 font-semibold">{label}</label>
            <div
                className="
                    mt-1
                    block
                    w-full
                    lg:flex-[2]
                "
            >
                <input type="checkbox" value="" className="sr-only peer" checked={value} />
                <label className="inline-flex items-center cursor-pointer">
                    <input type="checkbox" value="" className="sr-only peer" checked={value} onChange={
                        handleChange
                    } />
                    <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                </label>
            </div>
        </div>
    )
}