// Back button according to react-router-dom
export const ArrowBackButton = () => {
    return (
        <button onClick={() => {
            window.history.back();
        }} className="flex items-center justify-center p-1 rounded-full bg-gray-200 text-gray-800 hover:bg-gray-300">
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path>
            </svg>
        </button>
    )
}