import { Logo } from "../Logo";

export const SideBar = () => {
    return (
        <div>
            <button data-drawer-target="default-sidebar" data-drawer-toggle="default-sidebar" aria-controls="default-sidebar" type="button" className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200">
                <span className="sr-only">Open sidebar</span>
                <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path clip-rule="evenodd" fill-rule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                </svg>
            </button>

            <aside id="default-sidebar" className="fixed top-0 left-0 z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0" aria-label="Sidebar">
                <div className="h-full px-3 py-4 overflow-y-auto border">
                    {/* logo */}
                    <div className="mb-5">
                        <Logo />
                    </div>
                    <ul className="space-y-2 font-medium">
                        <li>
                            <a href="/" className="flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 group">
                                {/* <svg className="w-5 h-5 text-white transition duration-75 group-hover:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 21" >
                                    <path d="M9 21.9986V11.9986H15V21.9986M3 8.9986L12 1.9986L21 8.9986V19.9986C21 20.529 20.7893 21.0377 20.4142 21.4128C20.0391 21.7879 19.5304 21.9986 19 21.9986H5C4.46957 21.9986 3.96086 21.7879 3.58579 21.4128C3.21071 21.0377 3 20.529 3 19.9986V8.9986Z" stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" /></svg> */}
                                <span className="ms-3">Accueil</span>
                            </a>
                        </li>
                        <li>
                            <a href="/points-de-collecte" className="flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 group">
                                <span className="flex-1 ms-3 whitespace-nowrap">Points de collecte</span>
                            </a>
                        </li>
                        <li>
                            <a href="/emballages-standard" className="flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 group">
                                <span className="flex-1 ms-3 whitespace-nowrap">Emballages standards</span>
                            </a>
                        </li>
                        <li>
                            <a href="/produits-consignes" className="flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 group">
                                <span className="flex-1 ms-3 whitespace-nowrap">Produits consignés</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </aside>
        </div>
    );
}