import { Description } from "../../components/ui/text/Description"
import { Divider } from "../../components/ui/Divider"
import { PageTitle } from "../../components/ui/text/PageTitle"
import { SimpleInput } from "../../components/ui/inputs/SimpleInput"
import { ImageUpload } from "../../components/ui/inputs/ImageUpload"
import { Spacer } from "../../components/ui/Spacer"
import { Select } from "../../components/ui/inputs/Select"
import { Toggle } from "../../components/ui/inputs/Toggle"
import { PrimaryButton } from "../../components/ui/buttons/PrimaryButton"
import { useEffect, useState } from "react"
import { Error } from "../../components/ui/text/Error"
import { gql, useMutation, useQuery } from "@apollo/client"
import { Loader } from "../../components/ui/Loader"
import { Photo } from "../../types/Photo"

const CREATE_PACKAGING = gql`
    mutation($photos: [Float!]!, $collectible: Boolean!, $deposit: Float!, $weight: Float!, $shade: String!, $type: String!, $iconic: Boolean!, $name: String!){
    createPackaging(photos: $photos, collectible: $collectible, deposit: $deposit, weight: $weight, shade: $shade, type: $type, iconic: $iconic, name: $name) {
        id
    }
    }
`

const GET_TYPES_AND_SHADES = gql`
    query{
    packagingTypes{
        key
        value
    }
    shades{
        key
        value
    }
    }
`

export const CreateStandardPackaging = () => {
    const [createPackagingMutation, { loading, error }] = useMutation(CREATE_PACKAGING)
    const getTypesAndShadesQuery = useQuery(GET_TYPES_AND_SHADES)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const [name, setName] = useState("")
    const [photos, setPhotos] = useState<Photo[]>([])
    const [type, setType] = useState<{ key: string, value: string } | undefined>()
    const [iconic, setIconic] = useState<boolean>(false)
    const [shade, setShade] = useState<{ key: string, value: string } | undefined>()
    const [weight, setWeight] = useState<number | null>(null)
    const [deposit, setDeposit] = useState<number | null>(null)
    const [collectible, setCollectible] = useState<boolean>(true)
    const [isPhotoLoading, setIsPhotoLoading] = useState<boolean>(false)

    if (getTypesAndShadesQuery.loading) {
        return <Loader />
    }

    if (getTypesAndShadesQuery.error) {
        return <Error>Erreur lors du chargement des types et teintes</Error>
    }

    const handleCreatePackaging = async () => {
        // Check if all fields are filled
        if (!name || !type || !shade || !weight || !deposit) {
            setErrorMessage("Veuillez remplir tous les champs")
            return
        } else {
            createPackagingMutation({
                variables: {
                    photos: photos.map(photo => photo.id),
                    collectible: collectible,
                    deposit: deposit,
                    weight: weight,
                    shade: shade.value,
                    type: type.value,
                    iconic: iconic,
                    name: name
                },
                onError: (error) => {
                    setErrorMessage("Erreur lors de la création de l'emballage : " + error.message)
                }
            })
        }
    }

    const onPhotosChange = (photos: Photo[]) => {
        setPhotos(photos);
    }


    return (
        <div>
            <div className="max-w-96">
                <PageTitle>Nouvel emballage</PageTitle>
                <Description>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Earum, at distinctio? Autem, molestias. Atque, quidem.
                </Description>
            </div>

            <div className="py-5">
                <SimpleInput label="Nom de la référence" value={name} onChange={(value) => { setName(value) }} placeholder="Nom de la référence d'emballage" />

                <Spacer />
                <Divider />
                <Spacer />

                <ImageUpload label="Photos de l'emballage" onPhotosChange={onPhotosChange} setIsLoading={setIsPhotoLoading} />

                <Spacer />
                <Divider />
                <Spacer />

                <Select label="Type d'emballage" options={getTypesAndShadesQuery.data.packagingTypes} value={type} setValue={setType} />
                <Spacer />
                <Toggle label="Emballage iconique" value={iconic} setValue={setIconic} />
                <Spacer />
                <Select label="Teinte" options={getTypesAndShadesQuery.data.shades} value={shade} setValue={setShade} />
                <Spacer />
                <SimpleInput label="Poids (en grammes)" value={weight?.toString() || ""} onChange={(value) => {
                    if (value === "") {
                        setWeight(null)
                    }
                    if (value === "0") {
                        setWeight(0)
                    }
                    else if (Number(value)) {
                        setWeight(Number(value))
                    }
                }} placeholder="Poids de l'emballage" />

                <Spacer />
                <Divider />
                <Spacer />

                <SimpleInput label="Montant de la consigne (centimes d'euro)" value={deposit?.toString() || ""} onChange={(value) => {
                    if (value === "") {
                        setDeposit(null)
                    }
                    if (value === "0") {
                        setDeposit(0)
                    }
                    else if (Number(value)) {
                        setDeposit(Number(value))
                    }
                }} placeholder="Montant de la consigne" />


                <Spacer />
                <Divider />
                <Spacer />

                <Toggle label="Éligible à la collecte" value={collectible} setValue={setCollectible} />

                <Spacer />

                {errorMessage && <div>
                    <Error>{errorMessage}</Error>
                    <Spacer />
                </div>}

                {loading && <Loader />}
                {!loading && <PrimaryButton disabled={isPhotoLoading} onClick={handleCreatePackaging}>Créer l'emballage</PrimaryButton>}
            </div>
        </div>
    )
}