import { Link, useParams } from "react-router-dom"
import { PageTitle } from "../../components/ui/text/PageTitle"
import { gql, useQuery } from "@apollo/client";
import { Loader } from "../../components/ui/Loader";
import { Error } from "../../components/ui/text/Error";
import { Spacer } from "../../components/ui/Spacer";
import { PrimaryButton } from "../../components/ui/buttons/PrimaryButton";

const PACKAGING = gql`
    query($packagingId: Float!){
        packaging(id: $packagingId) {
            id
            name
            deposit
            iconic
            collectible
            weight
            photos {
                id
                url
            }
        }
    }
`

export const DisplayStandardPackaging = () => {
    let { id } = useParams();
    const { loading, error, data } = useQuery(PACKAGING, {
        variables: { packagingId: Number(id) }
    });

    return (
        <div>
            <div className="">
                {loading && <Loader />}
                {error && <Error>{error.message}</Error >}

                {data &&
                    <div>
                        <div className="flex">
                            <div className="flex-1">
                                <PageTitle>{data.packaging.name}</PageTitle>
                            </div>
                            <Link to={`/emballages-standard/${id}/modifier`}>
                                <PrimaryButton onClick={() => { }}>Modifier</PrimaryButton>
                            </Link>
                        </div>
                        <div>
                            <p><span className="font-semibold">Consigne :</span> {data.packaging.deposit / 100}€</p>
                            <p><span className="font-semibold">Emballage Iconique:</span> {data.packaging.iconic ? "Oui" : "Non"}</p>
                            <p><span className="font-semibold">Collectable :</span> {data.packaging.collectible ? "Oui" : "Non"}</p>
                            <p><span className="font-semibold">Poids :</span> {data.packaging.weight}g</p>
                        </div>
                        <Spacer />
                        <div className="flex flex-wrap">
                            {data.packaging.photos.map((photo: any) => {
                                return <img className="w-36" key={photo.id} src={photo.url} alt={data.packaging.name} />
                            })}
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}