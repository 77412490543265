import { gql, useQuery } from "@apollo/client";
import { Link, useParams } from "react-router-dom";
import { PageTitle } from "../../components/ui/text/PageTitle";
import { Loader } from "../../components/ui/Loader";
import { Error } from "../../components/ui/text/Error";
import { Description } from "../../components/ui/text/Description";
import { PrimaryButton } from "../../components/ui/buttons/PrimaryButton";
import { Spacer } from "../../components/ui/Spacer";


const PRODUCT = gql`
    query($id: Float!) {
        product(id: $id) {
            id
            name
            GTIN
            deposit
            collectible
            packaging{
                name
            }
            photos{
                id
                url
            }
        }
    }
`

export const DisplayProduct = () => {
    const { id } = useParams();
    const { data, loading, error } = useQuery(PRODUCT, {
        variables: {
            id: Number(id)
        }
    });

    return (
        <div>
            {loading &&
                <div>
                    <Loader />
                </div>
            }
            {error &&
                <div>
                    <Error>
                        {error.message}
                    </Error>
                </div>
            }
            {data && (
                <div>
                    <div className="flex">
                        <div className="flex-1">
                            <PageTitle>{data.product.name}</PageTitle>
                        </div>
                        <Link to={`modifier`}>
                            <PrimaryButton onClick={() => { }}>Modifier</PrimaryButton>
                        </Link>
                    </div>
                    <p><span className="font-semibold">GTIN : </span>{data.product.GTIN}</p>
                    <p><span className="font-semibold">Emballage : </span>{data.product.packaging.name}</p>
                    <p><span className="font-semibold">Consigne : </span>{data.product.deposit / 100}€</p>
                    <p><span className="font-semibold">Éligible : </span>{data.product.collectible ? "Oui" : "Non"}</p>
                    <Spacer/>
                    <div className="flex flex-wrap">
                        {data.product.photos.map((photo: { id: string, url: string }) => {
                            console.log(data.product)
                            return (
                                <img className="w-32" key={photo.id} src={photo.url} alt={data.product.name} />
                            )
                        })}
                    </div>
                </div>
            )}
        </div>
    )
}