export const Select = ({ label, options, value, setValue }: { label: string; options: { key: string, value: string }[]; value: { key: string, value: string } | undefined; setValue: React.Dispatch<React.SetStateAction<{ key: string, value: string } | undefined>> }) => {
    return (
        <div className="w-full lg:items-center lg:flex lg:max-w-[70%]">
            <label className="block text-sm text-gray-700 flex-1 font-semibold">{label}</label>
            <select
                value={value?.value || ""}
                onChange={(e) => {
                    setValue({
                        key: options.find(option => option.value === e.target.value)!.key,
                        value: e.target.value
                    })
                }}
                className="
                mt-1
                block
                px-3
                py-2
                w-full
                lg:flex-[2]
                border
                border-gray-300
                rounded-md
                shadow-sm
                focus:outline-none
                focus:ring-blue-500
                focus:border-blue-500
                sm:text-sm
                "
            >
                <option value="" disabled>Choisir une valeur</option>
                {options.map((option, index) => (
                    <option key={option.key} value={option.value}>{option.value}</option>
                ))}
            </select>
        </div>
    )
}