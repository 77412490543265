import { useParams } from "react-router-dom";
import { Logo } from "../../components/Logo";
import { Spacer } from "../../components/ui/Spacer";
import Barcode from "react-barcode";
import { gql, useQuery } from "@apollo/client";
import { Loader } from "../../components/ui/Loader";
import { useEffect, useState } from "react";
import { Voucher } from "../../types/Voucher";
import { Error } from "../../components/ui/text/Error";

const GET_VOUCHER = gql`
    query($encodedId: String!){
        displayVirtualVoucher(encodedId: $encodedId) {
            id
            value
            GTINSAndValues{
                GTIN
                value
            }
            collectPointName
            logoUrl
            createdAt
        }
    }
`

export const VirtualVoucher = () => {
    const { encodedId } = useParams();
    const [voucher, setVoucher] = useState<Voucher | null>(null);
    const { loading, error, data } = useQuery(GET_VOUCHER, {
        variables: { encodedId },
    });

    useEffect(() => {
        if (data) {
            setVoucher(data.displayVirtualVoucher);
            console.log(data)
        }
    }, [data]);

    return (
        <div className="flex flex-col items-center pt-5">

            <Spacer />
            {voucher?.logoUrl && <img src={voucher?.logoUrl} alt="logo du magasin" width={100} />}
            <h1 className="font-bold text-xl">{voucher?.collectPointName}</h1>
            <span>Montant total du bon d'achat : {(voucher?.value ?? 0) / 100}€</span>
            {voucher?.GTINSAndValues && voucher?.GTINSAndValues.length > 1
                &&
                <span className="text-orange-400 font-semibold text-center">Vous avez plusieurs bons d'achat à faire scanner car le montant total est trop élevé</span>
            }

            {loading && <Loader />}
            {error && <Error>{error.message}</Error>}
            {voucher && <>
                {voucher.GTINSAndValues.map((GTINValue, index) => {
                    return (
                        <div key={index} className="mb-20">
                            <Barcode value={GTINValue.GTIN} />
                            <p><span className="font-semibold">Montant :</span> {(GTINValue.value / 100).toFixed(2)}€</p>
                        </div>
                    )
                })}
            </>
            }
            <p>Date du bon d'achat : {voucher?.createdAt ? new Date(voucher.createdAt).toLocaleDateString() : ''}</p>
            <Spacer />
            <Spacer />
            <Logo />
        </div>
    );
};