import { Link } from "react-router-dom";
import { PrimaryButton } from "../../components/ui/buttons/PrimaryButton";
import { SearchBar } from "../../components/ui/inputs/SearchBar";
import { PageTitle } from "../../components/ui/text/PageTitle";
import { gql, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { CollectPoint } from "../../types/CollectPoints";
import { Loader } from "../../components/ui/Loader";
import { Error } from "../../components/ui/text/Error";

const COLLECT_POINTS = gql`
    query($page: Float!, $search: String){
    collectPoints(page: $page, search: $search) {
        id
        name
        zipCode
    }
    }
`

export const DisplayCollectPoints = () => {
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    const [collectPoints, setCollectPoints] = useState<CollectPoint[]>([]);
    const [hasSearch, setHasSearch] = useState(false);
    const { data, loading, error, refetch } = useQuery(COLLECT_POINTS, {
        variables: {
            page,
        }
    });

    useEffect(() => {
        if (data) {
            setCollectPoints(data.collectPoints);
        }
    }, [data])

    // Add useEffect to handleSearch if search is not empty and keyboard enter is pressed
    useEffect(() => {
        window.addEventListener('keypress', (e: KeyboardEvent) => {
            if (e.key === 'Enter') {
                handleSearch();
            }
        });
        return () => {
            window.removeEventListener('keypress', handleSearch);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, refetch, search])

    const handleSearch = () => {
        setPage(1)
        if (search === "") {
            setHasSearch(false);
            return refetch({
                search: null,
                page
            })
        }
        setHasSearch(true);
        refetch({
            search,
            page
        })
    }
    const colClass = "flex-1 p-2";
    return (
        <div>
            <div>
                <PageTitle>Points de collecte</PageTitle>
                <div className="flex w-full">
                    <div className="flex-1">
                        <Link to="/points-de-collecte/nouveau">
                            <PrimaryButton onClick={() => { }}>+ Point de collecte</PrimaryButton>
                        </Link>
                    </div>

                    <div className="flex gap-4">
                        {hasSearch && <button onClick={() => {
                            setHasSearch(false);
                            setSearch("");
                            refetch({
                                search: "",
                                page
                            })
                        }}>
                            <svg
                                className="text-gray-600 h-4 w-4 fill-current"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    fill="none"
                                    d="M0 0h24v24H0z"
                                />
                                <path
                                    d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                                />
                            </svg>
                        </button>}
                        <SearchBar placeholder="Nom du point de collecte" handleSearch={() => { }} onChange={setSearch} value={search} />
                    </div>

                </div>

                <div>
                    {loading && <Loader />}
                    {error && <Error>{error.message}</Error>}
                </div>



                <div className="flex flex-col">
                    <div className="flex w-full font-semibold justify-between bg-gray-200 mt-5 text-slate-500 pl-5 border rounded-t">
                        <div className={colClass}>Nom</div>
                    </div>

                    {collectPoints.map((cp: CollectPoint) => (
                        <Link to={`/points-de-collecte/${cp.id}/rapport`}>
                            <div key={cp.id} className="flex w-full justify-between bg-white text-slate-800 border py-3 pl-5">
                                <div className={colClass + " font-semibold"}>{cp.name}</div>
                            </div>
                        </Link>
                    ))}

                    <div className="flex border p-3 items-center rounded-b">
                        <div className="flex-1">
                            <p>Page {page}</p>
                        </div>
                        <div>
                            <PrimaryButton onClick={() => setPage(page - 1)} disabled={page === 1}>Précédent</PrimaryButton>
                            <PrimaryButton onClick={() => setPage(page + 1)}>Suivant</PrimaryButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}