import { VoucherSettings } from "./VoucherSettings";
import { UploadVouchers } from "./UploadVouchers";
import { gql, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { Loader } from "../../../../components/ui/Loader";
import { Error } from "../../../../components/ui/text/Error";
import { useEffect, useState } from "react";
import { Voucher } from "../../../../types/Voucher";
import { Spacer } from "../../../../components/ui/Spacer";
import { ImageUpload } from "../../../../components/ui/inputs/ImageUpload";

const VOUCHERS = gql`
    query($collectPointId: Float!){
    displayCollectPointVouchers(collectPointId: $collectPointId) {
        id
        GTIN
        value
    }
    }
`

export const CollectPointVouchers = () => {
    const [vouchers, setVouchers] = useState<Voucher[]>([]);
    const { id } = useParams();
    const { data, loading, error, refetch } = useQuery(VOUCHERS, {
        variables: { collectPointId: Number(id) }
    });

    useEffect(() => {
        if (data) {
            setVouchers(data.displayCollectPointVouchers);
        }
    }, [data]);

    return (
        <div className="pt-3">
            <div className="flex">
                <div className="flex-1">
                    <UploadVouchers refetch={refetch} />
                </div>
                <div className="flex-1">
                    <VoucherSettings />
                </div>
            </div>
            <Spacer/>
            <div>
                {loading && <Loader />}
                {error && <Error>Erreur: {error.message}</Error>}

                {vouchers.length > 0 && (
                    <div>
                        <div className="flex w-full font-semibold">
                            <div className="flex-1">EAN</div>
                            <div className="flex-1">Valeur</div>
                        </div>
                        {vouchers
.map((voucher) => {
                                return (
                                    <div key={voucher.id} className="flex w-full">
                                        <div className="flex-1">{voucher.GTIN}</div>
                                        <div className="flex-1">{voucher.value / 100}€</div>
                                    </div>
                                );
                            })}
                    </div>
                )}
            </div>
        </div>
    );
}