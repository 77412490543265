import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { CollectPointReportOutput } from "../../../types/CollectPointOutputReport";
import { gql, useQuery } from "@apollo/client";
import { Loader } from "../../../components/ui/Loader";
import { Error } from "../../../components/ui/text/Error";
import { DataCard } from "../../../components/ui/DataCard";


const DISPLAY_COLLECT_POINT_REPORT = gql`
    query($collectPointId: Float!, $startDate: DateTimeISO, $endDate: DateTimeISO){
        displayCollectPointReport(collectPointId: $collectPointId,startDate: $startDate,endDate: $endDate){
            quantityCollected
            depositRefunded
            weightCollected
            quantityCollectedByDate {
            date
            value
            }
            depositRefundedByDate {
            date
            value
            }
            weightCollectedByDate {
            date
            value
            }
        }
    }
`

export const CollectPointReport = () => {
    const { id } = useParams<{ id: string }>();
    const [report, setReport] = useState<CollectPointReportOutput | null>(null);

    const { data, loading, error } = useQuery(DISPLAY_COLLECT_POINT_REPORT, {
        variables: {
            collectPointId: Number(id),
        },
    });

    useEffect(() => {
        if (data) {
            setReport(data.displayCollectPointReport);
        }
    }, [data]);


    return (
        <div>
            {loading && <Loader />}
            {error && <Error>{error.message}</Error>}
            {!loading && !error && report &&
                <div className="flex-1">
                    <div className="flex justify-around gap-5 flex-wrap pt-5">
                        <DataCard title="Emballages collectés" value={report.quantityCollected.toString()} unit="Emballages" />
                        <DataCard title="Consigne remboursée" value={((report.depositRefunded / 100).toFixed(2)).toString().replace('.', ',')} unit="€" />
                        <DataCard title="Masse de déchets évitée" value={(report.weightCollected / 1000).toFixed(2).toString().replace('.', ',')} unit="Kg" />
                    </div>
                    <div className="mt-3 pt-5 px-5 flex items-center justify-center flex-1 h-96 border rounded-lg">
                        <ResponsiveContainer width={"100%"}>
                            <LineChart width={1000} height={400} data={report.quantityCollectedByDate}>
                                <Line type="monotone" dataKey="value" name="Bouteilles" stroke="#8884d8" />
                                <CartesianGrid stroke="#ccc" />
                                <XAxis dataKey="date" />
                                <YAxis dataKey={"value"} />
                                <Tooltip />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            }
        </div>
    );
}