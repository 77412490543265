import { gql, useMutation, useQuery } from "@apollo/client";
import {  useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import { Toggle } from "../../../../components/ui/inputs/Toggle";
import { Loader } from "../../../../components/ui/Loader";
import { Error } from "../../../../components/ui/text/Error";

const GET_SETTINGS = gql`
    query($collectPointId: Float!){
        getCollectPointVoucherSettings(collectPointId: $collectPointId) {
            activateCreditCardRefund
            activateVirtualVouchers
            activateVouchers
        }
    }
`

const EDIT_SETTINGS = gql`
    mutation($activateCreditCardRefund: Boolean!, $activateVirtualVouchers: Boolean!, $activateVouchers: Boolean!, $collectPointId: Float!){
        editCollectPointVoucherSettings(activateCreditCardRefund: $activateCreditCardRefund, activateVirtualVouchers: $activateVirtualVouchers, activateVouchers: $activateVouchers, collectPointId: $collectPointId) {
            id
        }
    }
`

export const VoucherSettings = () => {
    const { id } = useParams();
    const [editSettings, editSettingsResponse] = useMutation(EDIT_SETTINGS);
    const [activateVouchers, setActivateVouchers] = useState(false);
    const [activateVirtualVouchers, setActivateVirtualVouchers] = useState(false);
    const [activateCreditCardRefund, setActivateCreditCardRefund] = useState(false);

    const { data, loading, error } = useQuery(GET_SETTINGS, {
        variables: {
            collectPointId: Number(id)
        }
    });

    useEffect(() => {
        if (data) {
            setActivateVouchers(data.getCollectPointVoucherSettings.activateVouchers);
            setActivateVirtualVouchers(data.getCollectPointVoucherSettings.activateVirtualVouchers);
            setActivateCreditCardRefund(data.getCollectPointVoucherSettings.activateCreditCardRefund);
        }
    }, [data])

    if (loading || editSettingsResponse.loading) return <Loader />
    if (error) return <Error>{error.message}</Error>

    return (
        <div>
            <p className="font-semibold">Paramètres</p>
            <Toggle
                label={"Activer les bons d'achat"}
                value={activateVouchers}
                setValue={
                    (newValue) => {
                        editSettings({
                            variables: {
                                activateCreditCardRefund,
                                activateVirtualVouchers,
                                activateVouchers: newValue,
                                collectPointId: Number(id)
                            },
                            onCompleted: () => {
                                setActivateVouchers(newValue)
                            }
                        })
                    }}
            />
            <Toggle
                label={"Activer les bons digitaux (SMS)"}
                value={activateVirtualVouchers}
                setValue={
                    (newValue) => {
                        editSettings({
                            variables: {
                                activateCreditCardRefund,
                                activateVirtualVouchers: newValue,
                                activateVouchers,
                                collectPointId: Number(id)
                            },
                            onCompleted: () => {
                                setActivateVirtualVouchers(newValue)
                            }
                        })
                    }}
            />
            {/* <Toggle
                label={"Activer le remboursement par carte bancaire"}
                value={activateCreditCardRefund}
                setValue={
                    (newValue) => {
                        editSettings({
                            variables: {
                                activateCreditCardRefund: newValue,
                                activateVirtualVouchers,
                                activateVouchers,
                                collectPointId: Number(id)
                            },
                            onCompleted: () => {
                                setActivateCreditCardRefund(newValue)
                            }
                        })
                    }}
            /> */}
        </div>
    );
}