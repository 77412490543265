import { gql, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { Loader } from "../../../components/ui/Loader";
import { Error } from "../../../components/ui/text/Error";
import { useEffect, useState } from "react";
import { Divider } from "../../../components/ui/Divider";
import moment from "moment";
import 'moment/locale/fr';
import { PrimaryButton } from "../../../components/ui/buttons/PrimaryButton";
moment.locale('fr');

const SESSIONS = gql`
    query($collectPointId: Float!,$page: Float!){
    displaySessions(collectPointId: $collectPointId,page: $page) {
        id
        createdAt
        crateId
        refundChoice
        customerPhone
        user {
        name
        }
        voucher {
        value
        }
    }
    }
`;

export const CollectPointEvents = () => {
    const [page, setPage] = useState(1);
    const [sessions, setSessions] = useState([]);
    const { id } = useParams();
    const { loading, error, data } = useQuery(SESSIONS, {
        variables: { collectPointId: Number(id), page }
    })

    useEffect(() => {
        if (data) {
            setSessions(data.displaySessions);
        }
    }, [data]);

    console.log(sessions)

    if (error) return <Error>{error.message}</Error>;

    return (
        <div>
            <div className="flex gap-3 items-center my-3">
                <PrimaryButton onClick={() => setPage(page - 1)} disabled={page === 1}>Précédent</PrimaryButton>
                <span>Page {page}</span>
                <PrimaryButton onClick={() => setPage(page + 1)}>Suivant</PrimaryButton>
            </div>
            <div className="border">
                <div className="py-5 flex flex-1 font-semibold bg-slate-100 pl-5 rounded">
                    <p className="flex-1">Date</p>
                    <p className="flex-1">Numéro de caisse</p>
                    <p className="flex-1">Choix de remboursement</p>
                    <p className="flex-1">Téléphone du client</p>
                    <p className="flex-1">Caissier</p>
                    <p className="flex-1">Valeur du bon</p>
                </div>
                <Divider />
                {loading ? <Loader /> :
                    <>
                        {
                            sessions.map((session: any) => (
                                <div>
                                    <div key={session.id} className="flex gap-10 py-5 pl-5">
                                        <p className="flex-1">{moment(session.createdAt).format('LLL')}</p>
                                        <p className="flex-1">{session.crateId}</p>
                                        <p className="flex-1">{session.refundChoice}</p>
                                        <p className="flex-1">{session.customerPhone}</p>
                                        <p className="flex-1">{session.user.name}</p>
                                        <p className="flex-1">{(session.voucher.value / 100).toFixed(2)}€</p>
                                    </div>
                                    <Divider />
                                </div>
                            ))
                        }
                    </>
                }
            </div>
        </div>
    );

}