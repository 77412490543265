import { useState } from "react";
import { Logo } from "../../components/Logo"
import { gql, useMutation } from "@apollo/client";
import { Loader } from "../../components/ui/Loader";
import { Error } from "../../components/ui/text/Error";
import useLocalStorageState from "use-local-storage-state";

const loginMutation = gql`
    mutation($password: String!, $email: String!){
    adminLogin(password: $password, email: $email)
    }
`

export const Login = () => {
    const [login, { loading }] = useMutation(loginMutation);
    const [, setJWT] = useLocalStorageState<string>("jwt");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [inputError, setInputError] = useState("");

    const handleLogin = async () => {
        if (!email || !password) {
            setInputError("Veuillez remplir tous les champs");
            return;
        } else {
            setInputError("");
        }
        await login({
            variables: { email, password },
            onCompleted: (data) => {
                setJWT(data.adminLogin);
                window.location.href = "/";
            },
            onError: (error) => {
                setInputError("Adresse email ou mot de passe incorrect");
            }
        });
    }

    return (
        <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm flex flex-col justify-center items-center">
                <Logo />
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">SLC Administration</h2>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <div className="space-y-6">
                    <div>
                        <label className="block text-sm font-medium leading-6 text-gray-900">Adresse email</label>
                        <div className="mt-2">
                            <input value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Adresse email" id="email" name="email" type="email" required className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                        </div>
                    </div>

                    <div>
                        <div className="flex items-center justify-between">
                            <label className="block text-sm font-medium leading-6 text-gray-900">Mot de passe</label>
                        </div>
                        <div className="mt-2">
                            <input value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Mot de passe" id="password" name="password" type="password" required className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                        </div>
                    </div>

                    <div>
                        {inputError && <Error>{inputError}</Error>}
                        <button
                            type="submit"
                            className="flex w-full justify-center rounded-md bg-blue-800 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            onClick={handleLogin}
                        >
                            {!loading && "Connexion"}
                            {loading && <Loader />}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}