export const DataCard = ({ title, value, unit, startDate, endDate }: { title: string, value: string, unit: string, startDate?: Date, endDate?: Date }) => {
    return (
        <div className="flex flex-col gap-3 p-5 border rounded-lg flex-1">
            <div className="flex flex-col">
                <span className="font-semibold">{title}</span>
                <span>
                    {startDate && endDate ? `Du ${startDate.toLocaleDateString()} au ${endDate.toLocaleDateString()}` : "depuis le début"}
                </span>
            </div>
            <div className="flex gap-4 items-baseline">
                <span className="text-5xl font-semibold">{value}</span>
                <span>{unit}</span>
            </div>
        </div>
    )
}