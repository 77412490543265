import { createBrowserRouter } from "react-router-dom";
import { Root } from "./Root";
import { CreateStandardPackaging } from "../stories/standard-packaging/CreateStandardPackaging";
import { DisplayStandardPackagings } from "../stories/standard-packaging/DisplayStandardPackagings";
import { DisplayStandardPackaging } from "../stories/standard-packaging/DisplayStandardPackaging";
import { EditStandardPackaging } from "../stories/standard-packaging/EditStandardPackaging";
import { CreateProduct } from "../stories/product/CreateProduct";
import { DisplayProducts } from "../stories/product/DisplayProducts";
import { DisplayProduct } from "../stories/product/DisplayProduct";
import { EditProduct } from "../stories/product/EditProduct";
import { CreateCollectPoint } from "../stories/collect-point/CreateCollectPoint";
import { DisplayCollectPoints } from "../stories/collect-point/DisplayCollectPoints";
import { CollectPointHome } from "../stories/collect-point/collect-point-dashboard/Home";
import { CollectPointReport } from "../stories/collect-point/collect-point-dashboard/Report";
import { CollectPointVouchers } from "../stories/collect-point/collect-point-dashboard/voucher/Vouchers";
import { CollectPointEvents } from "../stories/collect-point/collect-point-dashboard/Events";
import { CollectPointSettings } from "../stories/collect-point/collect-point-dashboard/Settings";
import { CollectPointDevices } from "../stories/collect-point/collect-point-dashboard/device/DisplayDevices";
import { CollectPointUsers } from "../stories/collect-point/collect-point-dashboard/user/Users";
import { VirtualVoucher } from "../stories/virtual-voucher/VirtualVoucher";
import { LinkDeviceToCollectPoint } from "../stories/collect-point/collect-point-dashboard/device/LinkDeviceToCollectPoint";
import { NewUser } from "../stories/collect-point/collect-point-dashboard/user/NewUser";
import { Login } from "../stories/login/Login";

export const rootRouter = createBrowserRouter([{
    path: "/",
    element: <Root />,
    children: [
        { path: "/points-de-collecte", element: <DisplayCollectPoints /> },
        {
            path: "/points-de-collecte/:id", element: <CollectPointHome />,
            children: [
                { path: "rapport", element: <CollectPointReport /> },
                { path: "douchettes", element: <CollectPointDevices /> },
                { path: "douchettes/lier", element: <LinkDeviceToCollectPoint /> },
                { path: "bons-achat", element: <CollectPointVouchers /> },
                { path: "evenements", element: <CollectPointEvents /> },
                { path: "parametres", element: <CollectPointSettings /> },
                { path: "utilisateurs", element: <CollectPointUsers /> },
                { path: "utilisateurs/nouveau", element: <NewUser /> }
            ]
        },

        { path: "/points-de-collecte/nouveau", element: <CreateCollectPoint /> },

        { path: "/emballages-standard", element: <DisplayStandardPackagings /> },
        { path: "/emballages-standard/:id", element: <DisplayStandardPackaging /> },
        { path: "/emballages-standard/:id/modifier", element: <EditStandardPackaging /> },
        { path: "/emballages-standard/nouveau", element: <CreateStandardPackaging /> },

        { path: "/produits-consignes", element: <DisplayProducts /> },
        { path: "/produits-consignes/:id", element: <DisplayProduct /> },
        { path: "/produits-consignes/:id/modifier", element: <EditProduct /> },
        { path: "/produits-consignes/nouveau", element: <CreateProduct /> },
    ]
},
{
    path: "/virtual-voucher/:encodedId",
    element: <VirtualVoucher />,
},
{
    path: "/connexion",
    element: <Login />,
}
]
)