import { useParams } from "react-router-dom";
import { Description } from "../../../../components/ui/text/Description";
import { API_URL } from "../../../../api/url";
import { useState } from "react";
import { Error } from "../../../../components/ui/text/Error";

export const UploadVouchers = ({ refetch }: { refetch: () => void }) => {
    const { id } = useParams();
    const [error, setError] = useState<string | null>(null);
    const handleSendFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setError(null)
        if (event.target.files === null) {
            console.error("No file selected");
            return;
        }

        const file = event.target.files[0];
        if (!id) {
            console.error("No collect point ID found");
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('collectPointId', id);

        try {
            const response = await fetch(`${API_URL}/create-vouchers-from-csv`, {
                method: 'POST',
                body: formData
            });

            if (!response.ok) {
                throw setError(`Error: ${response.statusText}`);
            }

            refetch()
        } catch (error) {
            setError("Une erreur est survenue lors de l'envoi du fichier, assurez-vous qu'il est au bon format et réessayez.");
            console.error("Failed to send file:", error);
        }
    }

    return (
        <div>
            <p className="font-semibold">Importer une nouvelle liste d'EAN</p>
            <a className="underline text-blue-600" href="/create-vouchers-from-csv/download-template" download>Télécharger le modèle de fichier</a>
            <br />
            <input type="file" accept=".csv" onChange={handleSendFile} />
            {error && <Error>{error}</Error>}
            <div className="max-w-96">
                <Description>Vous devez importer une liste au format .csv (en utilisant ";" comme séparateur) avec les colonnes "GTIN" et "Value". Value représente la valeur du bon d'achat et est à exprimer en centime d'euro (indiquer 10 pour 0,10€)</Description>
            </div>
        </div>
    )
}