import { gql, useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CollectPoint } from "../../../types/CollectPoints";
import { Loader } from "../../../components/ui/Loader";
import { Spacer } from "../../../components/ui/Spacer";
import { SimpleInput } from "../../../components/ui/inputs/SimpleInput";
import { Divider } from "../../../components/ui/Divider";
import { PrimaryButton } from "../../../components/ui/buttons/PrimaryButton";
import { Description } from "../../../components/ui/text/Description";
import { Error } from "../../../components/ui/text/Error";
import { Toggle } from "../../../components/ui/inputs/Toggle";
import { ImageUpload } from "../../../components/ui/inputs/ImageUpload";
import { Photo } from "../../../types/Photo";

const COLLECT_POINT = gql`
query($collectPointId: Float!){
  collectPoint(id: $collectPointId) {
    id
    name
    address
    zipCode
    city
    email
    phone
    customId
    settings{
        askContainerScan
    }
    logo{
        id
        url
    }
  }
}`;

const EDIT_COLLECT_POINT = gql`
    mutation($phone: String!, $email: String!, $city: String!, $zipCode: String!, $address: String!, $name: String!, $editCollectPointId: Float!, $customId: String, $askContainerScan: Boolean!, $logoId: Float){
        editCollectPoint(phone: $phone, email: $email, city: $city, zipCode: $zipCode, address: $address, name: $name, id: $editCollectPointId,customId: $customId, askContainerScan: $askContainerScan, logoId: $logoId){ 
            id
        }
    }
`

export const CollectPointSettings = () => {
    const { id } = useParams<{ id: string }>();
    const [editCollectPoint, editCollectPointResponse] = useMutation(EDIT_COLLECT_POINT)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const [logo, setLogo] = useState<Photo | null>(null)
    const [logoLoading, setLogoLoading] = useState<boolean>(false)
    const [collectPoint, setCollectPoint] = useState<CollectPoint>({
        id: undefined,
        name: "",
        email: "",
        phone: "",
        address: "",
        zipCode: "",
        city: "",
        settings: {
            askContainerScan: false
        },
    })
    const { data, loading, error } = useQuery(COLLECT_POINT, {
        variables: { collectPointId: Number(id) }
    })
    useEffect(() => {
        if (data) {
            setCollectPoint(data.collectPoint)
            if (data.collectPoint.logo) {
                setLogo(data.collectPoint.logo)
            }
        }
    }
        , [data])

    const handleSave = async () => {
        setErrorMessage(null)
        if (!collectPoint.name || !collectPoint.email || !collectPoint.phone || !collectPoint.address || !collectPoint.zipCode || !collectPoint.city) {
            setErrorMessage("Veuillez remplir tous les champs obligatoires")
            return
        }

        await editCollectPoint({
            variables: {
                phone: collectPoint.phone,
                email: collectPoint.email,
                city: collectPoint.city,
                zipCode: collectPoint.zipCode,
                address: collectPoint.address,
                name: collectPoint.name,
                editCollectPointId: Number(id),
                customId: collectPoint.customId,
                askContainerScan: collectPoint.settings?.askContainerScan,
                logoId: logo?.id
            }
        }).then(() => {
            setErrorMessage(null)
        }).catch((e) => {
            setErrorMessage("Erreur lors de la sauvegarde")
        })
    }

    if (loading) return (<Loader />)
    if (error) return (<Error>Erreur</Error>)

    return (
        <div>
            <Spacer />

            <ImageUpload
                label="Logo"
                onPhotosChange={(photos) => {
                    setLogo(photos[0])
                }}
                setIsLoading={setLogoLoading}
                initialPhotos={logo ? [{ ...logo }] : []}
            />

            <Spacer />
            <Divider />
            <Spacer />

            <SimpleInput label="Établissement *" value={collectPoint?.name || ""} onChange={(value) => {
                setCollectPoint({ ...collectPoint, ...{ name: value } })
            }} placeholder={"Nom de l'établissement"} />

            <Spacer />
            <Divider />
            <Spacer />

            <SimpleInput label="Email *" value={collectPoint?.email || ""} onChange={(value) => {
                setCollectPoint({ ...collectPoint, ...{ email: value } })
            }} placeholder={"Email de l'établissement"} />
            <Spacer />
            <SimpleInput label="Téléphone *" value={collectPoint?.phone || ""} onChange={(value) => {
                setCollectPoint({ ...collectPoint, ...{ phone: value } })
            }} placeholder={"Téléphone de l'établissement"} />

            <Spacer />
            <Divider />
            <Spacer />

            <SimpleInput label="Adresse *" value={collectPoint?.address || ""} onChange={(value) => {
                setCollectPoint({ ...collectPoint, ...{ address: value } })
            }} placeholder={"Adresse de l'établissement"} />
            <Spacer />
            <SimpleInput label="Code postal *" value={collectPoint?.zipCode || ""} onChange={(value) => {
                setCollectPoint({ ...collectPoint, ...{ zipCode: value } })
            }} placeholder={"Code postal de l'établissement"} />
            <Spacer />
            <SimpleInput label="Ville *" value={collectPoint?.city || ""} onChange={(value) => {
                setCollectPoint({ ...collectPoint, ...{ city: value } })
            }} placeholder={"Ville de l'établissement"} />

            <Spacer />
            <Divider />
            <Spacer />

            <SimpleInput label="Identifiant personnalisé" value={collectPoint?.customId || ""} onChange={(value) => {
                setCollectPoint({ ...collectPoint, ...{ customId: value } })
            }} placeholder={"Identifiant personnalisé"} />
            <div className="max-w-56">
                <Description>L'identifiant personnalisé est envoyé par la douchette lors de requêtes à l'API. C'est utile pour l'intégration de l'API Swiv à des systèmes extérieurs.</Description>
            </div>

            <Spacer />
            <Toggle
                label={"Demander un scan du container"}
                value={collectPoint.settings?.askContainerScan || false}
                setValue={(newValue) => {
                    const askContainerScan = newValue as boolean;
                    setCollectPoint({
                        ...collectPoint, ...{
                            settings: {
                                askContainerScan
                            }
                        }
                    })
                }}
            />
            <div className="max-w-56">
                <Description>En activant cette option, l'hôte de caisse devra scanner le container dans lequel il dépose les bouteilles collectées.</Description>
            </div>

            <Spacer />
            {errorMessage && <div><Error>{errorMessage}</Error><Spacer /></div>}
            {editCollectPointResponse.error && <div><Error>{editCollectPointResponse.error.message}</Error><Spacer /></div>}
            {!editCollectPointResponse.loading && <PrimaryButton disabled={logoLoading} onClick={handleSave}>Sauvegarder</PrimaryButton>}
            {editCollectPointResponse.loading && <div><Loader /></div>}
        </div>
    );
}