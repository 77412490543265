import { useState } from "react";
import { SimpleInput } from "../../../../components/ui/inputs/SimpleInput";
import { Spacer } from "../../../../components/ui/Spacer";
import { PrimaryButton } from "../../../../components/ui/buttons/PrimaryButton";
import { gql, useMutation } from "@apollo/client";
import { Error } from "../../../../components/ui/text/Error";
import { Loader } from "../../../../components/ui/Loader";
import { useNavigate, useParams } from "react-router-dom";

const NEW_USER = gql`
    mutation( $name: String!, $collectPointId: Float!) {
        newUser(name: $name, collectPointId:$collectPointId) {
            id
        }
    }
`

export const NewUser = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [name, setName] = useState<string>("");
    const [newUser, newUserResponse] = useMutation(NEW_USER);

    const handleNewUser = () => {
        newUser({
            variables: {
                name,
                collectPointId: Number(id)
            },
            onCompleted: () => {
                navigate(`/points-de-collecte/${id}/utilisateurs`);
            },
            onError: (error) => {
                console.error(error);
            }
        })
    }

    return (
        <div className="pt-3">
            <p className="font-semibold">Créer un nouvel utilisateur</p>
            <Spacer />
            <SimpleInput label="Nom de l'utilisateur" value={name} onChange={setName} placeholder="Nom de l'utilisateur" />
            <Spacer />
            {newUserResponse.error && <div><Error>{newUserResponse.error.message}</Error><Spacer /></div>}
            {newUserResponse.loading && <Loader />}
            {!newUserResponse.loading && <PrimaryButton onClick={handleNewUser}>Créer l'utilisateur</PrimaryButton>}
        </div>
    );
}