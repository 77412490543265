/* eslint-disable react-hooks/exhaustive-deps */
import { Description } from "../../components/ui/text/Description"
import { Divider } from "../../components/ui/Divider"
import { PageTitle } from "../../components/ui/text/PageTitle"
import { SimpleInput } from "../../components/ui/inputs/SimpleInput"
import { ImageUpload } from "../../components/ui/inputs/ImageUpload"
import { Spacer } from "../../components/ui/Spacer"
import { Select } from "../../components/ui/inputs/Select"
import { Toggle } from "../../components/ui/inputs/Toggle"
import { PrimaryButton } from "../../components/ui/buttons/PrimaryButton"
import { useEffect, useState } from "react"
import { Error } from "../../components/ui/text/Error"
import { Loader } from "../../components/ui/Loader"
import { Photo } from "../../types/Photo"
import { gql, useMutation, useQuery } from "@apollo/client"
import { Enum } from "../../types/Enum"
import { Product } from "../../types/Product"

const PACKAGINGS = gql`
    query {
        packagings {
            id
            name
        }
    }
`

const CREATE_PRODUCT = gql`
    mutation($photos: [Float!]!, $packagingId: Float!, $deposit: Float, $gtin: String!, $name: String!, $collectible: Boolean!) {
    createProduct(photos: $photos, packagingId: $packagingId, deposit: $deposit, GTIN: $gtin, name: $name,collectible:$collectible) {
        id
    }
    }
`

export const CreateProduct = () => {
    const packagingsQuery = useQuery(PACKAGINGS);
    const [name, setName] = useState<string>("")
    const [createProduct, { data, loading, error }] = useMutation(CREATE_PRODUCT)
    const [photos, setPhotos] = useState<Photo[]>([]);
    const [deposit, setDeposit] = useState<number | null>(null)
    const [isPhotoLoading, setIsPhotoLoading] = useState<boolean>(false)
    const [packagings, setPackagings] = useState<Enum[]>([])
    const [packaging, setPackaging] = useState<Enum | undefined>(undefined)
    const [GTIN, setGTIN] = useState<string>("")
    const [collectible, setCollectible] = useState<boolean>(true)
    const [errorMessage, setErrorMessage] = useState<string>("")

    useEffect(() => {
        if (packagingsQuery.data) {
            setPackagings(packagingsQuery.data.packagings.map((packaging: { id: string, name: string }) => {
                return {
                    key: packaging.id,
                    value: packaging.name
                }
            }))
            setPackaging(packagings[0])
        }
    }, [packagingsQuery.data])

    const onPhotosChange = (photos: Photo[]) => {
        setPhotos(photos);
    }

    if (packagingsQuery.loading) {
        return <Loader />
    }

    const handleCreateProduct = async () => {
        console.log("handling")
        console.log(packaging?.key, deposit, GTIN, name)
        let product: Product = {
            name: name,
            GTIN: GTIN,
            collectible: collectible,
            deposit: deposit || undefined,
            photos: photos,
            packaging: packaging || { key: "", value: "" }
        }
        if (packaging && GTIN && name) {
            await createProduct({
                variables: {
                    photos: product.photos.map(photo => photo.id),
                    name: product.name,
                    gtin: product.GTIN,
                    deposit: product.deposit,
                    packagingId: packaging.key,
                    collectible: product.collectible
                },
                onError: (error) => {
                    setErrorMessage(error.message)
                }
            })
        } else {
            setErrorMessage("Veuillez remplir tous les champs indiqués par *")
        }
    }

    return (
        <div>
            <div className="max-w-96">
                <PageTitle>Nouveau produit</PageTitle>
                <Description>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Earum, at distinctio? Autem, molestias. Atque, quidem.
                </Description>
            </div>

            <div className="py-5">
                <SimpleInput label="Nom du produit *" value={name} onChange={setName} placeholder="Nom du produit" />
                <Spacer />
                <SimpleInput label="GTIN (EAN) *" value={GTIN} onChange={setGTIN} placeholder="GTIN du produit" />

                <Spacer />
                <Divider />
                <Spacer />

                <ImageUpload label="Photos de l'emballage" onPhotosChange={onPhotosChange} setIsLoading={setIsPhotoLoading} />

                <Spacer />
                <Divider />
                <Spacer />

                <Select label="Emballage *" options={packagings} setValue={setPackaging} value={packaging} />

                <Spacer />
                <Divider />
                <Spacer />

                <SimpleInput label="Montant de la consigne (centimes d'euro)" value={deposit?.toString() || ""} onChange={(value) => {
                    if (value === "") {
                        setDeposit(null)
                    }
                    if (value === "0") {
                        setDeposit(0)
                    }
                    else if (Number(value)) {
                        setDeposit(Number(value))
                    }
                }} placeholder="Montant de la consigne" />

                <Spacer />

                <Toggle label="Éligible à la collecte" value={collectible} setValue={setCollectible} />

                <Spacer />

                {loading && <Loader />}
                {errorMessage && <div><Error>{errorMessage}</Error><Spacer /></div>}
                {!loading && <PrimaryButton disabled={isPhotoLoading} onClick={handleCreateProduct}>Créer le produit</PrimaryButton>}
            </div>
        </div>
    )
}