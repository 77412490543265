import { gql, useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { Loader } from "../../components/ui/Loader";
import { Error } from "../../components/ui/text/Error";
import { PageTitle } from "../../components/ui/text/PageTitle";
import { SimpleInput } from "../../components/ui/inputs/SimpleInput";
import { Spacer } from "../../components/ui/Spacer";
import { Divider } from "../../components/ui/Divider";
import { ImageUpload } from "../../components/ui/inputs/ImageUpload";
import { Select } from "../../components/ui/inputs/Select";
import { Toggle } from "../../components/ui/inputs/Toggle";
import { useEffect, useState } from "react";
import { Photo } from "../../types/Photo";
import { Enum } from "../../types/Enum";
import { PrimaryButton } from "../../components/ui/buttons/PrimaryButton";
import { Product } from "../../types/Product";

const PRODUCT = gql`
    query($id: Float!) {
        product(id: $id) {
            id
            name
            GTIN
            deposit
            collectible
            packaging{
                name
            }
            photos{
                id
                url
            }
        }
    }
`

const PACKAGINGS = gql`
    query {
        packagings {
            id
            name
        }
    }
`

const EDIT_PRODUCT = gql`
    mutation($photos: [Float!]!, $packagingId: Float!, $collectible: Boolean!, $gtin: String!, $name: String!, $editProductId: Float!, $deposit: Float) {
        editProduct(photos: $photos, packagingId: $packagingId, collectible: $collectible, GTIN: $gtin, name: $name, id: $editProductId,deposit:$deposit) {
            id
        }
    }
`

export const EditProduct = () => {
    const packagingsQuery = useQuery(PACKAGINGS);
    const { id } = useParams();
    const { data, loading, error } = useQuery(PRODUCT, {
        variables: {
            id: Number(id)
        }
    });
    const [editProduct, editProductResponse] = useMutation(EDIT_PRODUCT);
    const [collectible, setCollectible] = useState<boolean>(false)
    const [name, setName] = useState<string>("")
    const [photos, setPhotos] = useState<Photo[]>([]);
    const [GTIN, setGTIN] = useState<string>("")
    const [deposit, setDeposit] = useState<number | null>(null)
    const [isPhotoLoading, setIsPhotoLoading] = useState<boolean>(false)
    const [packagings, setPackagings] = useState<Enum[]>([])
    const [packaging, setPackaging] = useState<Enum | undefined>(undefined)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)

    useEffect(() => {
        if (packagingsQuery.data) {
            setPackagings(packagingsQuery.data.packagings.map((packaging: { id: string, name: string }) => {
                return {
                    key: packaging.id,
                    value: packaging.name
                }
            }))
        }
    }, [packagingsQuery.data])

    useEffect(() => {
        if (data) {
            setName(data.product.name)
            setGTIN(data.product.GTIN)
            setDeposit(data.product.deposit)
            setCollectible(data.product.collectible)
            setPhotos(data.product.photos.map((photo: { id: string, url: string }) => {
                return {
                    id: photo.id,
                    url: photo.url
                }
            }))
            setPackaging(packagings.find(packaging => packaging.value === data.product.packaging.name))
        }
    }, [data, packagings])


    useEffect(() => { }, [data])

    const handleEditProduct = async () => {
        if (packaging && GTIN && name) {
            let product: Product = {
                id: Number(id),
                name: name,
                GTIN: GTIN,
                collectible: collectible,
                deposit: deposit || undefined,
                photos: photos,
                packaging: packaging
            }
            editProduct({
                variables: {
                    editProductId: product.id,
                    name: product.name,
                    gtin: product.GTIN,
                    collectible: product.collectible,
                    deposit: product.deposit,
                    photos: product.photos.map(photo => Number(photo.id)),
                    packagingId: Number(packaging?.key),
                }
            })
        } else {
            setErrorMessage("Veuillez remplir tous les champs")
        }
    }

    const onPhotosChange = (photos: Photo[]) => {
        setPhotos(photos);
    }

    return (
        <div>
            {loading && <Loader />}
            {error && <Error>{error.message}</Error>}
            {data && (
                <div>
                    <div className="max-w-96">
                        <PageTitle>Nouveau produit</PageTitle>
                    </div>

                    <div className="py-5">
                        <SimpleInput label="Nom du produit *" value={name} onChange={setName} placeholder="Nom du produit" />
                        <Spacer />
                        <SimpleInput label="GTIN (EAN) *" value={GTIN} onChange={setGTIN} placeholder="GTIN du produit" />

                        <Spacer />
                        <Divider />
                        <Spacer />

                        <ImageUpload label="Photos de l'emballage" onPhotosChange={onPhotosChange} setIsLoading={setIsPhotoLoading} initialPhotos={photos.slice()} />

                        <Spacer />
                        <Divider />
                        <Spacer />

                        <Select label="Emballage *" options={packagings} setValue={setPackaging} value={packaging} />

                        <Spacer />
                        <Divider />
                        <Spacer />

                        <SimpleInput label="Montant de la consigne (centimes d'euro)" value={deposit?.toString() || ""} onChange={(value) => {
                            if (value === "") {
                                setDeposit(null)
                            }
                            if (value === "0") {
                                setDeposit(0)
                            }
                            else if (Number(value)) {
                                setDeposit(Number(value))
                            }
                        }} placeholder="Montant de la consigne" />

                        <Spacer />

                        <Toggle label="Éligible à la collecte" value={collectible} setValue={setCollectible} />

                        <Spacer />

                        {editProductResponse.loading && <Loader />}
                        {editProductResponse.error && <div><Error>{editProductResponse.error.message}</Error><Spacer /></div>}
                        {errorMessage && <div><Error>{errorMessage}</Error><Spacer /></div>}
                        {!loading && <PrimaryButton disabled={isPhotoLoading} onClick={handleEditProduct}>Sauvegarder</PrimaryButton>}
                    </div>
                </div>
            )}
        </div>
    )
}