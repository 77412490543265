import { gql, useMutation, useQuery } from "@apollo/client";
import { Link, useParams } from "react-router-dom";
import { Loader } from "../../../../components/ui/Loader";
import { Error } from "../../../../components/ui/text/Error";
import { useEffect, useState } from "react";
import { Device } from "../../../../types/Device";
import { PrimaryButton } from "../../../../components/ui/buttons/PrimaryButton";
import { Spacer } from "../../../../components/ui/Spacer";
import { Divider } from "../../../../components/ui/Divider";

const DEVICES = gql`query($collectPointId: Float!){
    displayDevices(collectPointId: $collectPointId) {
      id
      SN
    }
  }
`

const REMOVE_DEVICE = gql`
    mutation($deviceId: Float!){
        removeDeviceFromCollectPoint(deviceId: $deviceId)
    }
`

export const CollectPointDevices = () => {
    const [devices, setDevices] = useState<Device[]>([]);
    const { id } = useParams();
    const { loading, error, data, refetch } = useQuery(DEVICES, {
        variables: { collectPointId: Number(id) }
    })
    const [removingDeviceId, setRemovingDeviceId] = useState<number | null>(null)
    const [removeDevice, removeDeviceResponse] = useMutation(REMOVE_DEVICE)

    useEffect(() => {
        if (data) {
            setDevices(data.displayDevices)
        }
    }, [data])

    //on page refocus, refetch data
    useEffect(() => {
        if (!loading && !error) {
            refetch()
        }
    }, [loading, error])

    if (loading) return <Loader />
    if (error) return <Error>{error.message}</Error>
    if (devices.length === 0) {
        return (
            <div className="p-5">
                <p className="font-semibold text-lg mb-3">Aucun appareil n'est lié à ce point de collecte</p>
                <Link to={"lier"}>
                    <PrimaryButton onClick={() => { }}>Lier une douchette</PrimaryButton>
                </Link>
            </div>
        )
    }

    const handleDelete = (id: number) => {
        if (window.confirm("Voulez-vous vraiment retirer cet appareil de ce point de collecte ?")) {
            setRemovingDeviceId(id)
            removeDevice({
                variables: {
                    deviceId: id
                },
                onCompleted: () => {
                    setDevices(devices.filter(device => device.id !== id))
                }
            })
        }
    }

    return (
        <div className="pt-3">
            <Link to={"lier"}>
                <PrimaryButton onClick={() => { }}>+ Douchette</PrimaryButton>
                {
                    removeDeviceResponse.error &&
                    <div>
                        <Spacer />
                        <Error>{removeDeviceResponse.error.message}</Error>
                    </div>
                }
            </Link>
            <Spacer />
            <Divider />
            {devices.map(device => {
                return (
                    <div key={device.id} className="p-5 border-b flex">
                        <div className="flex-1">
                            <p className="font-semibold text-lg">Douchette {device.id}</p>
                            <p>Numéro de série: {device.SN}</p>
                        </div>
                        {/* Delete button (icon) */}
                        {removingDeviceId === device.id ?
                            <Loader />
                            :
                            <PrimaryButton onClick={() => { handleDelete(Number(device.id)) }}>
                                Supprimer
                            </PrimaryButton>
                        }

                    </div>
                )
            })}
        </div >
    );
};