import { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { API_URL } from "../../../api/url";
import { Loader } from "../Loader";
import { Photo } from "../../../types/Photo";

const fileTypes = ["JPG", "PNG", "JPEG"];

/**
 * 
 * @param label : string, label of the input
 * @param onPhotosChange : (photos: Photo[]) => void, function to call when photos are uploaded or deleted, photos are the new value of the photos state
 * @param setIsLoading : (value: boolean) => void, function to call when the component loading state changes. It is useful to disable the submit button when the component is loading
 * @returns 
 */
export const ImageUpload = ({ label, onPhotosChange, setIsLoading, initialPhotos }: { label: string, onPhotosChange: (photos: Photo[]) => void, setIsLoading: (value: boolean) => void, initialPhotos?: Photo[] }) => {
    const [photos, setPhotos] = useState<Photo[]>([])
    const [isUploaderLoading, setIsUploaderLoading] = useState(false)

    useEffect(() => {
        if (initialPhotos) {
            setPhotos(initialPhotos)
        }
    }, [initialPhotos])

    useEffect(() => {
        setIsLoading(isUploaderLoading)
    }, [isUploaderLoading, setIsLoading])

    const handleFile = (files: FileList) => {
        console.log(files);
        // send files to the server (/aws-s3/upload-images) on an array named "images"
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append("images", files[i]);
        }
        setIsUploaderLoading(true)
        fetch(`${API_URL}/aws-s3/upload-images`, {
            method: "POST",
            body: formData,
        }).then(async (response) => {
            const data = await response.json();
            const photosData = data.photos;;
            onPhotosChange(
                [...photos, ...photosData]
            );
            setPhotos([...photos, ...photosData]);
            setIsUploaderLoading(false)
        })
            .catch((error) => {
                setIsLoading(false)
                // handle error
            });
    }

    return (
        <div className="w-full lg:items-center lg:flex lg:max-w-[70%]">
            <label className="block text-sm text-gray-700 flex-1 font-semibold">{label}</label>
            <div className="flex-[2]">
                <FileUploader handleChange={handleFile} name={"files"} multiple types={fileTypes}>
                    <div
                        className="
                            cursor-pointer
                            flex
                            flex-col
                            items-center
                            mt-1
                            py-8
                            gap-3
                            lg:flex-[2]
                            border
                             border-gray-300
                            rounded-md
                            shadow-sm
                            focus:outline-none
                             focus:ring-indigo-500
                             focus:border-indigo-500
                            sm:text-sm
                        "
                    >
                        {!isUploaderLoading && <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="3" y="3" width="40" height="40" rx="20" fill="#F2F4F7" />
                            <rect x="3" y="3" width="40" height="40" rx="20" stroke="#F9FAFB" stroke-width="6" />
                            <g clip-path="url(#clip0_14_739)">
                                <path d="M26.3333 26.3332L23 22.9999M23 22.9999L19.6666 26.3332M23 22.9999V30.4999M29.9916 28.3249C30.8044 27.8818 31.4465 27.1806 31.8165 26.3321C32.1866 25.4835 32.2635 24.5359 32.0351 23.6388C31.8068 22.7417 31.2862 21.9462 30.5555 21.3778C29.8248 20.8094 28.9257 20.5005 28 20.4999H26.95C26.6977 19.5243 26.2276 18.6185 25.5749 17.8507C24.9222 17.0829 24.104 16.4731 23.1817 16.0671C22.2594 15.661 21.2571 15.4694 20.2501 15.5065C19.243 15.5436 18.2575 15.8085 17.3676 16.2813C16.4777 16.7541 15.7066 17.4225 15.1122 18.2362C14.5177 19.05 14.1155 19.9879 13.9358 20.9794C13.756 21.9709 13.8034 22.9903 14.0743 23.961C14.3452 24.9316 14.8327 25.8281 15.5 26.5832" stroke="#475467" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                            </g>
                            <defs>
                                <clipPath id="clip0_14_739">
                                    <rect width="20" height="20" fill="white" transform="translate(13 13)" />
                                </clipPath>
                            </defs>
                        </svg>}
                        {isUploaderLoading && <Loader />}
                        <div>
                            <p className="text-center"><span className="font-semibold text-blue-700">Cliquer pour importer</span> ou drag and drop</p>
                            <p className="text-gray-500 text-center">JPEG, JPG, PNG (max. 1200x800px)</p>
                        </div>
                    </div>
                </FileUploader>

                {/* preview photos on a div*/}
                <div className="flex flex-wrap gap-2 mt-2">
                    {photos.map((photo, index) => (
                        <div key={index} className="relative">
                            <img src={photo.url} alt="preview" className="w-16 h-16 object-cover" />
                            <button
                                onClick={() => {
                                    const newPhotos = photos.filter((_, i) => i !== index);
                                    setPhotos(newPhotos);
                                    onPhotosChange(newPhotos);
                                }}
                                className="absolute top-0 right-0 bg-white p-1 rounded-full"
                            >
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 1L17 17M17 1L1 17" stroke="#475467" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}