import { Link, useParams } from "react-router-dom";
import { PrimaryButton } from "../../../../components/ui/buttons/PrimaryButton";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Loader } from "../../../../components/ui/Loader";
import { Error } from "../../../../components/ui/text/Error";
import { useEffect, useState } from "react";
import { Spacer } from "../../../../components/ui/Spacer";
import { Divider } from "../../../../components/ui/Divider";
import { API_URL } from "../../../../api/url";

const USERS = gql`
    query($collectPointId: Float!){
        displayCollectPointUsers(collectPointId: $collectPointId) {
            id
            name
            email
        }
    }
`

const DELETE_USER = gql`
    mutation($userId: Float!){
        deleteUser(userId: $userId)
    }
`

export const CollectPointUsers = () => {
    const { id } = useParams();
    const { data, loading, error, refetch } = useQuery(USERS, {
        variables: {
            collectPointId: Number(id)
        }
    });
    const [users, setUsers] = useState([]);
    const [removingUserId, setRemovingUserId] = useState<number | null>(null);
    const [deleteUser, deleteUserResponse] = useMutation(DELETE_USER);

    useEffect(() => {
        if (data) {
            setUsers(data.displayCollectPointUsers);
        }
    }, [data]);

    //refetch on focus
    useEffect(() => {
        refetch()
    }, [loading, data, refetch])

    const handleDeleteUser = (userId: number) => {
        setRemovingUserId(userId);
        deleteUser({
            variables: {
                userId
            },
            onCompleted: () => {
                setUsers(users.filter((user: any) => user.id !== userId));
                setRemovingUserId(null);
            }
        })
    }

    const handleGenerateBarcode = async (userId: number) => {
        // post /user/generate-user-bar-code with body {userId}
        const response = await fetch(`${API_URL}/user/generate-user-bar-code`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ userId })
        })
        if (response.ok) {
            const responseData = await response.json();
            const pdfBlob = new Blob([new Uint8Array(responseData.pdf.data)], {
                type: 'application/pdf',
            });

            // Créer un URL pour le blob
            const pdfUrl = window.URL.createObjectURL(pdfBlob);

            // Créer un lien <a> pour le téléchargement
            const link = document.createElement('a');
            link.href = pdfUrl;
            link.setAttribute('download', 'designs.pdf');

            // Ajouter le lien au document et cliquer dessus pour démarrer le téléchargement
            document.body.appendChild(link);
            link.click();

            // Nettoyer l'URL après le téléchargement
            window.URL.revokeObjectURL(pdfUrl);
        }
    }

    if (loading) return <Loader />
    if (error) return <Error>{error.message}</Error>
    return (
        <div className="pt-3">
            <Link to="nouveau">
                <PrimaryButton onClick={() => { }}>+ Utilisateur</PrimaryButton>
            </Link>
            {deleteUserResponse.error && <Error>{deleteUserResponse.error.message}</Error>}
            <Spacer />
            <Divider />
            <div>
                {users.map((user: any) => {
                    return (
                        <div key={user.id} className="p-5 border-b flex">
                            <div className="flex-1">
                                <p className="font-semibold text-lg">{user.name}</p>
                                {user.email && <p>{user.email}</p>}
                                <span onClick={() => handleGenerateBarcode(user.id)} className="text-blue-500 underline cursor-pointer">Générer le code-barres personnel</span>
                            </div>
                            {removingUserId === user.id ?
                                <Loader />
                                :
                                <PrimaryButton onClick={() => { handleDeleteUser(user.id) }}>
                                    Supprimer
                                </PrimaryButton>}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}