export const SearchBar = ({ placeholder, onChange, value, handleSearch }: { placeholder: string, onChange: (value: string) => void, value: string, handleSearch: () => void }) => {
    return (
        <div className="flex items-center justify-center">
            <div className="relative">
                <input
                    type="text"
                    className="border-2 w-64 bg-white h-10 px-5 pr-10 rounded-lg text-sm focus:outline-none"
                    placeholder={placeholder}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                />
                <button onClick={handleSearch} className="absolute right-0 top-0 mt-3 mr-4">
                    <svg
                        className="text-gray-600 h-4 w-4 fill-current"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                    >
                        <path
                            fill="none"
                            d="M0 0h24v24H0z"
                        />
                        <path
                            d="M9 2C5.13 2 2 5.13 2 9s3.13 7 7 7c1.54 0 2.94-.5 4.09-1.36l5.61 5.61 1.41-1.41L14.5 14.5A6.96 6.96 0 0 1 16 9c0-3.87-3.13-7-7-7zm0 2c2.76 0 5 2.24 5 5s-2.24 5-5 5-5-2.24-5-5 2.24-5 5-5z"
                        />
                    </svg>
                </button>
            </div>
        </div>
    );
}