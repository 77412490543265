import React from 'react';
import ReactDOM from 'react-dom/client';
import { ApolloClient, InMemoryCache, ApolloProvider, DefaultOptions, HttpLink, ApolloLink } from '@apollo/client';
import './index.css';
import { rootRouter } from './app-root/root-router';
import { RouterProvider } from 'react-router-dom';
import { API_URL } from './api/url';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

const httpLink = new HttpLink({
  uri: `${API_URL}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('jwt')?.replace(/"/g, '') || '';
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const errorLink = onError((error) => {
  if (error.graphQLErrors) {
    // if there is a code === "UNAUTHORIZED" then redirect to /connexion page
    error.graphQLErrors.forEach((graphQLError) => {
      if (graphQLError.extensions?.code === 'UNAUTHORIZED') {
        window.location.href = "/connexion";
        localStorage.removeItem('jwt');
      }
    });
  }
});

const client = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions,
  headers: {
    'Authorization': `Bearer ${localStorage.getItem('jwt')?.replace(
      /"/g
      , ''
    ) || ''}`
  }
});

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <RouterProvider router={rootRouter} />
    </ApolloProvider>
  </React.StrictMode>
);