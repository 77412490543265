import { useEffect, useState } from "react"
import { gql, useMutation, useQuery } from "@apollo/client"
import { useParams } from "react-router-dom"
import { Description } from "../../components/ui/text/Description"
import { Divider } from "../../components/ui/Divider"
import { PageTitle } from "../../components/ui/text/PageTitle"
import { SimpleInput } from "../../components/ui/inputs/SimpleInput"
import { ImageUpload } from "../../components/ui/inputs/ImageUpload"
import { Spacer } from "../../components/ui/Spacer"
import { Select } from "../../components/ui/inputs/Select"
import { Toggle } from "../../components/ui/inputs/Toggle"
import { PrimaryButton } from "../../components/ui/buttons/PrimaryButton"
import { Error } from "../../components/ui/text/Error"
import { Loader } from "../../components/ui/Loader"
import { Photo } from "../../types/Photo"

const EDIT_PACKAGING = gql`
    mutation($name: String!, $iconic: Boolean!, $type: String!, $shade: String!, $weight: Float!, $deposit: Float!, $collectible: Boolean!, $photos: [Float!]!, $packagingId: Float!){
  editPackaging(name: $name, iconic: $iconic, type: $type, shade: $shade, weight: $weight, deposit: $deposit, collectible: $collectible, photos: $photos, packagingId: $packagingId) {
    id
  }
}
`

const GET_TYPES_AND_SHADES = gql`
    query{
        packagingTypes{
            key
            value
        }
        shades{
            key
            value
        }
    }
`

const PACKAGING = gql`
    query($packagingId: Float!){
        packaging(id: $packagingId) {
            id
            name
            deposit
            iconic
            collectible
            weight
            shade
            type
            photos {
                id
                url
            }
        }
    }
`

export const EditStandardPackaging = () => {
    const { id } = useParams();

    const packagingQuery = useQuery(PACKAGING, {
        variables: { packagingId: Number(id) }
    });

    const [editPackagingMutation, { loading, error }] = useMutation(EDIT_PACKAGING);
    const getTypesAndShadesQuery = useQuery(GET_TYPES_AND_SHADES);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [name, setName] = useState("");
    const [photos, setPhotos] = useState<Photo[]>([]);
    const [type, setType] = useState<{ key: string, value: string } | undefined>();
    const [iconic, setIconic] = useState<boolean>(false);
    const [shade, setShade] = useState<{ key: string, value: string } | undefined>();
    const [weight, setWeight] = useState<number | null>(null);
    const [deposit, setDeposit] = useState<number | null>(null);
    const [collectible, setCollectible] = useState<boolean>(true);
    const [isPhotoLoading, setIsPhotoLoading] = useState<boolean>(false);

    useEffect(() => {
        setType(getTypesAndShadesQuery.data?.packagingTypes[0]);
        setShade(getTypesAndShadesQuery.data?.shades[0]);
        if (packagingQuery.data && getTypesAndShadesQuery.data) {
            const packagingActualShade = getTypesAndShadesQuery.data?.shades.find((shade: { key: string, value: string }) => shade.key === packagingQuery.data.packaging.shade)
            const packagingActualType = getTypesAndShadesQuery.data?.packagingTypes.find((type: { key: string, value: string }) => type.key === packagingQuery.data.packaging.type)
            setName(packagingQuery.data.packaging.name);
            setPhotos(packagingQuery.data.packaging.photos);
            setType(packagingActualType);
            setIconic(packagingQuery.data.packaging.iconic);
            setShade(packagingActualShade);
            setWeight(packagingQuery.data.packaging.weight);
            setDeposit(packagingQuery.data.packaging.deposit);
            setCollectible(packagingQuery.data.packaging.collectible);
        }
    }, [getTypesAndShadesQuery.data, packagingQuery.data])


    if (getTypesAndShadesQuery.loading) {
        return <Loader />
    }

    if (getTypesAndShadesQuery.error) {
        return <Error>Erreur lors du chargement des types et teintes</Error>
    }

    const handleCreatePackaging = async () => {
        // Check if all fields are filled
        console.log('here')
        if (!name || !type || !shade || !weight || !deposit) {
            setErrorMessage("Veuillez remplir tous les champs")
            return
        } else {
            editPackagingMutation({
                variables: {
                    photos: photos.map(photo => photo.id),
                    collectible: collectible,
                    deposit: deposit,
                    weight: weight,
                    shade: shade.value,
                    type: type.value,
                    iconic: iconic,
                    name: name,
                    packagingId: Number(id)
                },
                onError: (error) => {
                    setErrorMessage("Erreur lors de l'édition de l'emballage : " + error.message)
                }
            })
        }
    }

    const onPhotosChange = (photos: Photo[]) => {
        setPhotos(photos);
    }

    return (
        <div>
            <div className="max-w-96">
                <PageTitle>Modifier l'emballage</PageTitle>
                <Description>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Earum, at distinctio? Autem, molestias. Atque, quidem.
                </Description>
            </div>

            {packagingQuery.loading && <Loader />}
            {packagingQuery.error && <Error>Erreur lors du chargement de l'emballage : {packagingQuery.error.message}</Error>}

            {!packagingQuery.loading && !packagingQuery.error && <div className="py-5">
                <SimpleInput label="Nom de la référence" value={name} onChange={(value) => { setName(value) }} placeholder="Nom de la référence d'emballage" />

                <Spacer />
                <Divider />
                <Spacer />

                <ImageUpload label="Photos de l'emballage" onPhotosChange={onPhotosChange} setIsLoading={setIsPhotoLoading} initialPhotos={photos.slice()} />

                <Spacer />
                <Divider />
                <Spacer />

                <Select label="Type d'emballage" options={getTypesAndShadesQuery.data.packagingTypes} value={type} setValue={(value) => { setType(value) }} />
                <Spacer />
                <Toggle label="Emballage iconique" value={iconic} setValue={setIconic} />
                <Spacer />
                <Select label="Teinte" options={getTypesAndShadesQuery.data.shades} value={shade} setValue={(value) => { setShade(value) }} />
                <Spacer />
                <SimpleInput label="Poids (en grammes)" value={weight?.toString() || ""} onChange={(value) => {
                    if (value === "") {
                        setWeight(null)
                    }
                    if (value === "0") {
                        setWeight(0)
                    }
                    else if (Number(value)) {
                        setWeight(Number(value))
                    }
                }} placeholder="Poids de l'emballage" />

                <Spacer />
                <Divider />
                <Spacer />

                <SimpleInput label="Montant de la consigne (centimes d'euro)" value={deposit?.toString() || ""} onChange={(value) => {
                    if (value === "") {
                        setDeposit(null)
                    }
                    if (value === "0") {
                        setDeposit(0)
                    }
                    else if (Number(value)) {
                        setDeposit(Number(value))
                    }
                }} placeholder="Montant de la consigne" />


                <Spacer />
                <Divider />
                <Spacer />

                <Toggle label="Éligible à la collecte" value={collectible} setValue={setCollectible} />

                <Spacer />

                {errorMessage && <div>
                    <Error>{errorMessage}</Error>
                    <Spacer />
                </div>}

                {loading && <Loader />}
                {!loading && <PrimaryButton disabled={isPhotoLoading} onClick={handleCreatePackaging}>Sauvegarder</PrimaryButton>}
            </div>}
        </div>
    )
}