export const PrimaryButton = ({ children, onClick, disabled }: { children: React.ReactNode; onClick: () => void, disabled?: boolean }) => {
    return (
        <button
            disabled={disabled}
            onClick={onClick}
            className="
                inline-flex
                items-center
                justify-center
                px-4
                py-2
                border
                border-transparent
                text-base
                font-medium
                rounded-md
                text-white
                bg-blue-800
                hover:bg-blue-900
                focus:outline-none
                focus:ring-2
                focus:ring-offset-2
                focus:ring-blue-500
                transition-colors
                duration-150
                disabled:opacity-50
            "
        >
            {children}
        </button>
    )
}