import { SideBar } from "../components/navigation/SideBar";
import { Outlet } from "react-router-dom";
import useLocalStorageState from 'use-local-storage-state'

export const Root = () => {
    // check if user is logged in
    const [JWT] = useLocalStorageState<string>("jwt");
    if (!JWT) {
        window.location.href = "/connexion";
    }
    return (
        <div>
            <SideBar />
            <div className="p-4 sm:ml-64">
                <Outlet />
            </div>
        </div>
    );
}