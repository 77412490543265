import { Outlet, useParams } from "react-router-dom";
import { PageTitle } from "../../../components/ui/text/PageTitle"
import { gql, useQuery } from "@apollo/client";
import { Loader } from "../../../components/ui/Loader";
import { Error } from "../../../components/ui/text/Error";
import { CollectPoint } from "../../../types/CollectPoints";
import { useEffect, useState } from "react";
import { SelectSubPage } from "../../../components/navigation/SelectSubPage";
import { CollectPointVouchers } from "./voucher/Vouchers";
import { CollectPointEvents } from "./Events";
import path from "path";

const COLLECT_POINT = gql`
query($collectPointId: Float!){
  collectPoint(id: $collectPointId) {
    id
    name
    address
    zipCode
    city
    email
    phone
  }
}`;

export const CollectPointHome = () => {
    const { id } = useParams<{ id: string }>();
    const { data, loading, error } = useQuery(COLLECT_POINT, {
        variables: { collectPointId: Number(id) }
    })
    const [collectPoint, setCollectPoint] = useState<CollectPoint | null>(null)

    useEffect(() => {
        if (data) {
            setCollectPoint(data.collectPoint)
        }
    }, [data])

    if (loading) return (<Loader />)
    if (error) return (<Error>Error: {error.message}</Error>)

    return (
        <div>
            <PageTitle>{collectPoint?.name}</PageTitle>
            <p>{collectPoint?.address}, {collectPoint?.zipCode} {collectPoint?.city}</p>
            <p className="flex gap-1">
                <a href={`tel:${collectPoint?.phone}`}>{collectPoint?.phone}</a> <br />
                <a href={`mailto:${collectPoint?.email}`}>{collectPoint?.email}</a>
            </p>
            <SelectSubPage
                paths={[
                    { title: "Rapport", path: "rapport" },
                    { title: "Douchettes", path: "douchettes" },
                    { title: "Utilisateurs", path: "utilisateurs" },
                    { title: "Paramètres", path: "parametres" },
                    { title: "Événements", path: "evenements" },
                    { title: "Bons d'achat", path: "bons-achat" }
                ]}
            />
            <Outlet />
        </div>
    )
}