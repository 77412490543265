export const SimpleInput = ({ label, value, onChange, placeholder, type }: { label: string, value: string, onChange: (value: string) => void, placeholder: string, type?: string }) => {
    return (
        <div className="w-full lg:items-center lg:flex lg:max-w-[70%]">
            <label className="block text-sm text-gray-700 flex-1 font-semibold">{label}</label>
            <input
                type="text"
                value={value}
                onChange={(e) => {
                    console.log(e.target.value)
                    onChange(e.target.value)
                }}
                placeholder={placeholder}
                className="
                    mt-1
                    block
                    px-3
                    py-2
                    w-full
                    lg:flex-[2]
                    border
                    border-gray-300
                    rounded-md
                    shadow-sm
                    focus:outline-none
                    focus:ring-blue-500
                    focus:border-blue-500
                    sm:text-sm
                    "
            />
        </div>
    )
}