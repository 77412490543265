import { Link } from "react-router-dom";

export const SelectSubPage = ({ paths }: { paths: { title: string, path: string }[] }) => {
    const actualPath = window.location.pathname.split("/");
    return (
        <div>
            <div className="flex border-b-2">
                {paths.map(({ title, path }, index) => {
                    const isSelected = (actualPath.find((p) => p === path) !== undefined);
                    return (
                        <div className={`px-6 py-2 ${isSelected ? "border-b-4 border-blue-700" : ""}`}>
                            <Link to={path}>
                                <span className={`cursor-pointer font-semibold ${isSelected ? "text-blue-700" : "text-gray-400"}`}>
                                    {title}
                                </span>
                            </Link>
                        </div>
                    )
                })}
            </div>
            <div>
            </div>
        </div>
    );
}