import { useState } from "react"
import { SimpleInput } from "../../components/ui/inputs/SimpleInput"
import { PageTitle } from "../../components/ui/text/PageTitle"
import { Spacer } from "../../components/ui/Spacer"
import { Divider } from "../../components/ui/Divider"
import { Description } from "../../components/ui/text/Description"
import { PrimaryButton } from "../../components/ui/buttons/PrimaryButton"
import { Error } from "../../components/ui/text/Error"
import { gql, useMutation } from "@apollo/client"
import { CollectPoint } from "../../types/CollectPoints"

const CREATE_COLLECT_POINT = gql`
    mutation($city: String!, $zipCode: String!, $address: String!, $phone: String!, $email: String!, $name: String!, $customId: String){
        createCollectPoint(city: $city, zipCode: $zipCode, address: $address, phone: $phone, email: $email, name: $name,customId: $customId) {
            id
        }
    }
`

export const CreateCollectPoint = () => {
    const [createCollectPoint, { loading }] = useMutation(CREATE_COLLECT_POINT)
    const [name, setName] = useState<string>("")
    const [address, setAddress] = useState<string>("")
    const [zipCode, setZipCode] = useState<string>("")
    const [city, setCity] = useState<string>("")
    const [customId, setCustomId] = useState<string>("")
    const [email, setEmail] = useState<string>("")
    const [phone, setPhone] = useState<string>("")
    const [errorMessage, setErrorMessage] = useState<string>("")

    const handleCreateCollectPoint = () => {
        if (name === "" || address === "" || zipCode === "" || city === "" || email === "" || phone === "") {
            setErrorMessage("Veuillez remplir tous les champs obligatoires (*)")
            return
        } else {
            const collectPoint: CollectPoint = {
                name,
                address,
                zipCode,
                city,
                customId,
                email,
                phone
            };
            setErrorMessage("");
            createCollectPoint({
                variables: collectPoint,
                onError: (error) => {
                    setErrorMessage("Une erreur est survenue lors de la création du point de collecte : " + error.message)
                }
            })
        }
    }


    return (
        <div>
            <div>
                <PageTitle>Créer un point de collecte</PageTitle>
            </div>

            <div>
                <Spacer />
                <SimpleInput label="Nom du point de collecte *" value={name} onChange={setName} placeholder={"Nom du point de collecte"} />

                <Spacer />
                <Divider />
                <Spacer />

                <SimpleInput label="Email *" value={email} onChange={setEmail} placeholder={"Email"} />
                <Spacer />

                <SimpleInput label="Téléphone *" value={phone} onChange={setPhone} placeholder={"Numéro de téléphone"} />

                <Spacer />
                <Divider />
                <Spacer />

                <SimpleInput label="Adresse du point de collecte *" value={address} onChange={setAddress} placeholder={"Adresse du point de collecte"} />
                <Spacer />
                <SimpleInput label="Code postal *" value={zipCode} onChange={setZipCode} placeholder={"Code postal"} />
                <Spacer />
                <SimpleInput label="Ville *" value={city} onChange={setCity} placeholder={"Ville"} />

                <Spacer />
                <Divider />
                <Spacer />

                <SimpleInput label="Identifiant personnalisé" value={customId} onChange={setCustomId} placeholder={"Identifiant personnalisé"} />
                <div className="max-w-56">
                    <Description>L'identifiant personnalisé est envoyé par la douchette lors de requêtes à l'API. C'est utile pour l'intégration de l'API à des systèmes extérieurs.</Description>
                </div>
                <Spacer />
                {errorMessage && <div><Error>{errorMessage}</Error><Spacer /></div>}
                <PrimaryButton onClick={handleCreateCollectPoint}>Créer le point de collecte</PrimaryButton>
            </div>
        </div>
    )
}