import { Link } from "react-router-dom"
import { PrimaryButton } from "../../components/ui/buttons/PrimaryButton"
import { Divider } from "../../components/ui/Divider"
import { Spacer } from "../../components/ui/Spacer"
import { PageTitle } from "../../components/ui/text/PageTitle"
import { gql, useQuery } from "@apollo/client"
import { Loader } from "../../components/ui/Loader"
import { Key, ReactElement, JSXElementConstructor, ReactNode } from "react"
import { Error } from "../../components/ui/text/Error"

const defaultPackagingUrl = "https://swiv-douchette.s3.eu-west-3.amazonaws.com/default-packaging.png"

const PACKAGINGS = gql`
    query{
        packagings {
            id
            name
            photos {
                url
            }
            deposit
        }
}
`

export const DisplayStandardPackagings = () => {
    let { data, loading, error } = useQuery(PACKAGINGS);
    if (loading) {
        return (
            <div>
                <div className="w-full flex flex-1">
                    <div className="flex-1">
                        <PageTitle>Emballages</PageTitle>
                    </div>
                    <Link to="/emballages-standard/nouveau">
                        <PrimaryButton onClick={() => { }}>+ Emballage</PrimaryButton>
                    </Link>
                </div>

                <Spacer />
                <div className="flex">
                    <Loader />
                </div>
            </div>
        )
    }
    if (data) {
        return (
            <div>
                <div className="w-full flex flex-1">
                    <div className="flex-1">
                        <PageTitle>Emballages</PageTitle>
                    </div>
                    <Link to="/emballages-standard/nouveau">
                        <PrimaryButton onClick={() => { }}>+ Emballage</PrimaryButton>
                    </Link>
                </div>

                <Spacer />

                <div className="flex gap-8 flex-wrap">
                    {error && <Error>{error.message}</Error>}
                    {data.packagings.map((packaging: { id: number; photos: any[]; name: string; deposit: number }) => {
                        return (
                            <Link to={`/emballages-standard/${packaging.id}`}>
                                <div key={packaging.id} className="w-36 md:w-56 lg:w-64 border rounded-lg p-2">
                                    <img className="object-contain" src={packaging.photos[0]?.url || defaultPackagingUrl} alt={packaging.name} />
                                    <Divider />
                                    <p className="font-semibold">{packaging.name}</p>
                                    <p>Consigne par défaut : {packaging.deposit / 100} €</p>
                                </div>
                            </Link>
                        )
                    })}
                </div>
            </div>
        )
    }

    return (
        <div>
            <div className="w-full flex flex-1">
                <div className="flex-1">
                    <PageTitle>Emballages</PageTitle>
                </div>
                <Link to="/emballages-standard/nouveau">
                    <PrimaryButton onClick={() => { }}>+ Emballage</PrimaryButton>
                </Link>
            </div>

            <Spacer />

            <p>Une erreur est survenue</p>
        </div>
    )
}